<style lang="scss">
  .add-network-modal{
    .network-section {
      padding: 20px 0;
      border-bottom: 2px solid #5e50ee;

      .invalid-feedback{
        margin-bottom: 10px;
      }
    }
  }
</style>

<template>
  <div class="add-network-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(addNetwork)">

        <section class="mb-2">
          <section class="">
            <div class="network-item">
              <label>Name</label>
              <validation-provider
                v-slot="validationContext"
                name="Name"
                :rules="{ required: true, min: 5 }"
              >
                <b-form-input
                  v-model="network.name"
                  :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter name*"
                />

                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </section>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button type="submit" variant="primary">Add new network</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import NetworkService from '../../../services/NetworkService'

  export default {
    components: {},
    props: {},
    data() {
      return {
        network: {
          name: '',
        },
      };
    },
    watch: {
    },
    mounted() {},
    methods: {
      addNetwork() {
        NetworkService.createNetwork(this.network).then(res => {
          this.$toast.success(`Added network ${this.network.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.$router.push({name: 'network-edit', params: {uid: res.data.uid}})
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not add network, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    },
  };
</script>
